

export default function parameterStatus(name, value, bps ){

    // console.log(name, bps);
    if (value === 0 || value === undefined){
        // return "#C9c9c5"
        return "No Data";
    }
    if (bps[name][0] < value && value <= bps[name][1]) {
        // return '#00b050'
        return 'Good';
    }
    else if (bps[name][1] < value && value <= bps[name][2]) {
        // return '#92d050'
        return 'Satisfactory';
    }
    else if (bps[name][2] < value && value <= bps[name][3]) {
        // return '#E9D30E'
        return 'Moderately Polluted';
    }
    else if (bps[name][3] < value && value <= bps[name][4]) {
        // return '#ed7d31'
        return 'Poor';
    }
    else if (bps[name][4] < value && value <= bps[name][5]) {
        // return '#ff0000'
        return 'Very Poor';
    }
    else if (bps[name][5] < value) {
        // return '#c00000'
        return 'Severe';
    }

}