import React, { createContext } from 'react';

// Create the Context
export const ValContext = createContext();

// Create a Provider Component
export const ValProvider = ({ value, children }) => {
    return (
        <ValContext.Provider value={value}>
            {children}
        </ValContext.Provider>
    );
};