import React from 'react'

function MinorBody({ data }) { 

    return (
        <div className='smart-minor'>
            {data.map((item, index) => {
                let color;
                switch (item.status) {
                    case 'Good':
                        color = '#00b050';
                        break;
                    case 'Satisfactory':
                        color = '#92d050';
                        break;
                    case 'Moderately Polluted':
                        color = '#E9D30E';
                        break;
                    case 'Poor':
                        color = '#ed7d31';
                        break;
                    case 'Very Poor': 
                        color = '#ff0000';
                        break;
                    case 'Severe':
                        color = '#c00000';
                        break;
                    default:
                        color = "#C9c9c5";
                        break;
                }

                return (
                    (item.name !== 'Temperature' && item.name !== 'Humidity' && item.name !== 'temp' && item.name !== 'humid') && (
                        <div key={index}>
                            <p>{item.name}</p>
                            <p style={{ color: color }}>{item.value}</p>
                            <p>{item.unit}</p>
                        </div>
                    )
                );
            })}
        </div>
    );
}

export default MinorBody;


// export default MinorBody