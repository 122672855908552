import React, {useState, useEffect, useContext} from 'react';
import { ValContext } from '../../ValContext';
import './scrolling.css';

function ScrollingParameters(data) {

    const temp = data.data;
    const jsonData = useContext(ValContext);
    const [currentIndex, setCurrentIndex] = useState(0);

    function calculateColor(status){
        if(status === 'Good'){
            return '#00b050';
        }
        else if(status === 'Satisfactory'){
            return '#92d050';
        }
        else if(status === 'Moderate'){
            return '#E9D30E';
        }
        else if(status === 'Poor'){
            return '#ed7d31';
        }
        else if(status === 'Very Poor'){
            return '#ff0000';
        }
        else if(status === 'Severe'){
            return '#c00000';
        }
        else{
            return '#C9c9c5';
        }
    }

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % temp.length);
        }, jsonData.pagingInterval * 1000);

        return () => clearInterval(interval);
    }, [temp.length, jsonData.pagingInterval]);

    return (
        <>
            {temp.length > 0 && (
                <div key={currentIndex} className='scrolling-wrapper'>
                    <div 
                        style={{ 
                            position: "sticky", 
                            top: 0, 
                            backgroundColor: "black", 
                            width: "100%", 
                            zIndex: 10,
                        }}>
                        <p className='location-name'>{temp[currentIndex][0]?.location}</p>
                    </div>
                    {temp[currentIndex].map((item, j) => (
                        <section className='scrolling-section'>
                            <p key={j}>{item.name === "humid" ? "HUMIDITY" : item.name.toUpperCase()}</p>
                            <p style={{ color: calculateColor(item.name === 'aqi' ? item.locationStatus : item.status) }}>
                                {item.value}
                            </p>
                            <p>{item.unit}</p>
                        </section>
                    ))}
                </div>
            )}
        </>
    )
}

export default ScrollingParameters